import { useEffect, useState, useCallback } from "react"
import axios from "utils/axiosInstance"

export const useGetProducts = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(true)

    const getProducts = useCallback(async () => {
        setLoading(true)

        try {
            const { data } = await axios.get("/product")

            const options = data.map((item) => ({
                key: item.id,
                id: item.id,
                value: item.id,
                label: item.descrizione_ricambio,
                descrizione_ricambio: item.descrizione_ricambio,
                codice_ricambio: item.codice_ricambio,
                qty: item.qty,
                price: item.price,
            }))

            setData(options)
        } catch (err) {
            console.error(err.response)

            if (err.response) {
                const { data } = err.response
                setError(data)
            }
        }

        setLoading(false)
    }, [])

    useEffect(() => {
        getProducts()

        return () => {
            setData([])
            setLoading(true)
            setError(true)
        }

        // eslint-disable-next-line
    }, [])

    return { data, loading, error, getProducts, refetch: getProducts }
}

export const useCreateProduct = ({ onComplete }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const createProduct = useCallback(async (productData) => {
        setLoading(true)

        try {
            const { data } = await axios.post("/product/new", productData)

            if (onComplete) onComplete(data)
        } catch (err) {
            console.error(err.response)

            if (err.response) {
                const { data } = err.response
                setError(data.msg)
            }
        }

        setLoading(false)

        // eslint-disable-next-line
    }, [])

    return { createProduct, loading, error }
}

export const useEditProduct = ({ onComplete }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const putUpdateProductById = useCallback(async (productData) => {
        setLoading(true)

        try {
            const { data } = await axios.put(`/product/${productData.id}`, productData)

            if (onComplete) onComplete(data)
        } catch (err) {
            console.error(err.response)

            if (err.response) {
                const { data } = err.response
                setError(data.msg)
            }
        }

        setLoading(false)

        // eslint-disable-next-line
    }, [])

    return { putUpdateProductById, loading, error }
}

export const useRemoveProduct = ({ onComplete }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const deleteProduct = useCallback(async (productData) => {
        setLoading(true)

        try {
            const { data } = await axios.delete(`/product/${productData}`)

            if (onComplete) onComplete(data)
        } catch (err) {
            console.error(err.response)

            if (err.response) {
                const { data } = err.response
                setError(data.msg)
            }
        }

        setLoading(false)

        // eslint-disable-next-line
    }, [])

    return { deleteProduct, loading, error }
}
