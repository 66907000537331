import React, { Component, Fragment } from "react"
import { Button, Form, Table } from "react-bootstrap"

import SelectProducts from "pages/InvoicePage/components/Products/SelectProducts"

import { BiTrash } from "react-icons/bi"

import "bootstrap/dist/css/bootstrap.min.css"

class InvoiceItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            billToOptions: [],
        }

        this.handleItemizedItemEdit = this.handleItemizedItemEdit.bind(this)
        this.handleRowAdd = this.handleRowAdd.bind(this)
        this.handleRowDel = this.handleRowDel.bind(this)
    }

    handleItemizedItemEdit(event, id) {
        const { label, value, name, descrizione_ricambio, price, qty } = event.target;
        console.log("event item:",event);

        const updatedItems = this.props.items.map((item) => {
            if (item.id === id) {
                if (label) {
                    item.id = value
                    item.name = label
                    item.description = descrizione_ricambio
                    item.price = price
                } else if (name) item[name] = value
            }
            //console.log(item);
            return item
        });

        this.props.updateItems(updatedItems)
        this.props.isValidItems(updatedItems)
    }

    handleRowAdd() {
        const newItems = [...this.props.items]
        newItems.push({
            id: new Date().getTime(),
            name: "",
            description: "",
            quantity: 1,
            price: 0,
        })
        this.props.updateItems(newItems)
        this.props.isValidItems(newItems)
    }

    handleRowDel(item) {
        const updatedItems = this.props.items.filter((i) => i.id !== item.id)
        this.props.updateItems(updatedItems)
        this.props.isValidItems(updatedItems)
    }

    render() {
        const currency = this.props.currency

        const itemTable = this.props.items.map((item, index) => {
            return (
                <Fragment key={item.id}>
                    <tr>
                        <td colSpan={3}>
                            {index === 0 ? (
                                <SelectProducts
                                    value={{ value: item.id, label: item.name , description: item.description, price: item.price }}
                                    onChange={(option) =>
                                        this.handleItemizedItemEdit(
                                            { target: { ...option } },
                                            item.id
                                        )
                                    }
                                />
                            ) : (
                                <SelectProducts
                                    value={{ value: item.price, label: item.name, description: item.description, price: item.price}}
                                    onChange={(option) =>
                                        this.handleItemizedItemEdit(
                                            { target: { ...option } },
                                            item.id
                                        )
                                    }
                                    hide
                                />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th>Quantità</th>
                        <th>Prezzo</th>
                        <th className="text-center">Elimina</th>
                    </tr>
                    <tr key={item.id}>
                        <td style={{ minWidth: "70px" }}>
                            <Form.Control
                                type="number"
                                name="quantity"
                                min="1"
                                step="1"
                                value={parseInt(item.quantity)}
                                onChange={(event) =>
                                    this.handleItemizedItemEdit(event, item.id)
                                }
                            />
                        </td>
                        <td style={{ minWidth: "130px" }}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        {currency}
                                    </span>
                                </div>
                                <input
                                    type="number"
                                    name="price"
                                    min="1"
                                    step="0.01"
                                    value={parseFloat(item.price)}
                                    onChange={(event) =>
                                        this.handleItemizedItemEdit(
                                            event,
                                            item.id
                                        )
                                    }
                                    className="form-control text-end"
                                />
                            </div>
                        </td>
                        <td
                            className="text-center"
                            style={{ minWidth: "50px" }}
                        >
                            <BiTrash
                                onClick={() => this.handleRowDel(item)}
                                style={{
                                    height: "33px",
                                    width: "33px",
                                    padding: "7.5px",
                                }}
                                className="text-white mt-1 btn btn-danger"
                            />
                        </td>
                    </tr>
                </Fragment>
            )
        })

        return (
            <div>
                <Table>
{/*                     <thead>
                        <tr>
                            <th>Quantità</th>
                            <th>Prezzo</th>
                            <th className="text-center">Elimina</th>
                        </tr>
                    </thead> */}
                    <tbody className="border-t">{itemTable}</tbody>
                </Table>
                <hr style={{ marginTop: 50 }} />
                <Button
                    className=" d-block mb-8 mt-4 bg-[#004b8f] border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-xs md:text-base min-w-[150px]"
                    onClick={this.handleRowAdd}
                >
                    Aggiungi Prodotti
                </Button>
            </div>
        )
    }
}

export default InvoiceItem
