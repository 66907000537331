import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SelectProducts from "./SelectProducts";
import { useRemoveProduct } from "../../services/product";
import { IS_PRODUCTION } from "utils/consts";

const mockValue = {
  name: "Test new",
  description: "Test",
  cod: "Test",
  telephone: "12345678",
  tax_id_code: "1234567",
  vat_number: "123456",
  billing_phone: "123456789",
  billing_email: "hello@test.com",
  billing_first_name: "Test",
  billing_last_name: "Test",
  billing_company: "Test",
  billing_address_1: "Test",
  billing_address_2: "Test",
  billing_city: "Test",
  billing_state: "Test",
  billing_postcode: "1234",
  billing_country: "Italy",
};

const RemoveClientModal = (props) => {
  const { show, handleClose, onRemoveComplete } = props;

  const { deleteProduct, loading } = useRemoveProduct({
    onComplete: (data) => {
      onRemoveComplete(data);
      handleClose();
    },
  });

  const { register, handleSubmit } = useForm({
    defaultValues: IS_PRODUCTION ? {} : mockValue,
  });

  const onSubmit = async (data) => {
    await deleteProduct(product.value);
  };

  const [product, setProduct] = useState({ label: null, value: null });
  const onChange = (data) => {
    setProduct(data);
    onRemoveComplete(data);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Rimuovere Prodotto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectProducts value={product} onChange={onChange} hide="yes" />

          <div className="div-row" style={{ marginTop: 50, marginBottom: 10 }}>
            <Button
              type="button"
              disabled={loading}
              variant="secondary"
              onClick={handleClose}
              className="bg-gray-700"
            >
              Chiudi
            </Button>
            <Button
              type="submit"
              disabled={loading}
              variant="primary"
              className="bg-blue-700"
            >
              {loading ? "Invio in corso..." : "Rimuovere"}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

RemoveClientModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCreateComplete: PropTypes.func.isRequired,
};

export default RemoveClientModal;
