import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

import EditProductModal from "./EditProductModal";

const EditProduct = (props) => {
  const { onChange } = props;

  const [showAddClientModal, setShowAddClientModal] = useState(false);

  const onCreateClientComplete = async (client) => {
    onChange({ value: client.id, label: client.name });
    setShowAddClientModal(false)
  };

  return (
    <div className="flex justify-center">
      <Button
        size="sm"
        variant="outline-primary"
        onClick={() => setShowAddClientModal(true)}
        className="text-xs md:text-base min-w-[150px] border-[#004b8f] hover:bg-[#004b8f] text-[#004b8f] hover:border-[#004b8f]"
      >
        Modifica Prodotto
      </Button>

      <EditProductModal
        show={showAddClientModal}
        handleClose={() => setShowAddClientModal(false)}
        onEditComplete={onCreateClientComplete}
      />
    </div>
  );
};

EditProduct.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default EditProduct;
