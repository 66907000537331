import React from "react"
import { Row, Col, Card, Button, Container, Spinner } from "react-bootstrap"

import { useAuthContext } from "context/auth-context"

const LoginPage = (props) => {
    const { login, isLoading } = useAuthContext()

    return (
        <div
            className="d-flex flex-column align-items-center justify-content-center w-100"
            style={{ minHeight: "100vh" }}
        >
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} md={6} lg={4}>
                        <Card>
                            <Card.Body className="py-6">
                                <img
                                    className="m-auto mb-2"
                                    src="https://deltat-technology.web.app/assets/img/delta_t_technology.webp"
                                    alt="Delta T Technology Logo"
                                />
                                {!isLoading ? (
                                    <h2 className="text-center my-4">
                                        Accedi per utilizzare la piattaforma
                                    </h2>
                                ) : null}
                                {isLoading ? (
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <Spinner
                                            animation="border"
                                            variant="primary"
                                        />
                                    </div>
                                ) : (
                                    <Button
                                        className="w-100 bg-[#024b9e] hover:bg-[#f88e1f] duration-300 transition"
                                        onClick={login}
                                    >
                                        Log In
                                    </Button>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LoginPage
