import { useEffect, useState, useCallback } from "react"
import axios from "utils/axiosInstance"

export const useGetClients = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(true)

    const getClients = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await axios.get("/client")
            const options = data.map((item) => ({
                value: item.id,
                label: item.name,
                name: item.name,
                phone: item.phone,
                email: item.email,
                city: item.city,
                cap: item.cap,
                address: item.address,
                description: item.description
            })).filter((item) => item.name !== "" && item.name !== undefined);
            setData(options)
        } catch (err) {
            console.error(err.response)

            if (err.response) {
                const { data } = err.response
                setError(data)
            }
        }

        setLoading(false)
    }, [])

    useEffect(() => {
        getClients()

        return () => {
            setData([])
            setLoading(true)
            setError(true)
        }

        // eslint-disable-next-line
    }, [])

    return { data, loading, error, getClients, refetch: getClients }
}

export const useCreateClient = ({ onComplete }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const createClient = useCallback(async (clientData) => {
        setLoading(true)

        try {
            const { data } = await axios.post("/client/new", clientData)

            if (onComplete) onComplete(data)
        } catch (err) {
            console.error(err.response)

            if (err.response) {
                const { data } = err.response
                setError(data.msg)
            }
        }

        setLoading(false)

        // eslint-disable-next-line
    }, [])

    return { createClient, loading, error }
}

export const useEditClient = ({ onComplete }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const putUpdateClientById = useCallback(async (clientData) => {
        setLoading(true)

        try {
            console.log(clientData)
            const { data } = await axios.put(`/client/${clientData.id}`, clientData);
            console.log("fetching data;", data);

            if (onComplete) onComplete(data)
        } catch (err) {
            console.error(err)

            if (err.response) {
                const { data } = err.response
                setError(data.msg)
            }
        }

        setLoading(false)

        // eslint-disable-next-line
    }, [])

    return { putUpdateClientById, loading, error }
}

export const useRemoveClient = ({ onComplete }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const deleteClient = useCallback(async (clientData) => {
        setLoading(true)

        try {
            const { data } = await axios.delete(`/client/${clientData}`)

            if (onComplete) onComplete(data)
        } catch (err) {
            console.error(err.response)

            if (err.response) {
                const { data } = err.response
                setError(data.msg)
            }
        }

        setLoading(false)

        // eslint-disable-next-line
    }, [])

    return { deleteClient, loading, error }
}
