import React, { useState } from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import { Form, Button } from "react-bootstrap";

import CreateProductModal from "./CreateProductModal";
import { useGetProducts } from "../../services/product";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 35,
  }),
};

const SelectProducts = (props) => {
  const { onChange, value } = props;

  const { data, loading, refetch } = useGetProducts();

  const [showAddProductModal, setShowAddProductModal] = useState(false);

  const onCreateProductComplete = async (product) => {
    await refetch();
    onChange({ value: product.id, label: product.descrizione_ricambio });
  };

  return (
    <div>
      <Form.Label className="fw-bold">Prodotto:</Form.Label>

      <div style={{ marginBottom: 10 }}>
        <Select
          options={data}
          onChange={onChange}
          value={value}
          required
          isLoading={loading}
          styles={customStyles}
          maxMenuHeight={300}
          menuPosition={"fixed"}
          classNamePrefix="react-select"
          className="single-select"
          placeholder="Seleziona prodotto..."
          filterOption={(option, rawInput) => {
            const descrizione_ricambio = option.data.descrizione_ricambio;
            const search = rawInput.toLowerCase();

            return descrizione_ricambio.includes(search)
          }}
        />
      </div>

      {!props.hide && (
        <Button
          size="sm"
          variant="outline-primary"
          style={{ margin: "10px 0 20px 0" }}
          onClick={() => setShowAddProductModal(true)}
          className="border-[#004b8f] hover:bg-[#004b8f] text-[#004b8f] hover:border-[#004b8f]"
        >
          Crea Nuovo Prodotto
        </Button>
      )}

      <CreateProductModal
        show={showAddProductModal}
        handleClose={() => setShowAddProductModal(false)}
        onCreateComplete={onCreateProductComplete}
      />
    </div>
  );
};

SelectProducts.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default SelectProducts;
