import React, { createContext, useContext, useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"

const AuthContext = createContext({
    isLoggedIn: false,
    user: null,
    isLoading: false,
    login: () => {},
    logout: () => {},
})

export const AuthContextProvider = (props) => {
    const {
        loginWithPopup,
        logout: auth0Logout,
        user: auth0User,
        isLoading,
    } = useAuth0()

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [user, setUser] = useState(null)
    useEffect(() => {
        if (auth0User) {
            setIsLoggedIn(true)
            setUser(auth0User)
        } else {
            setIsLoggedIn(false)
            setUser(null)
        }
    }, [auth0User])

    const login = async () => {
        try {
            await loginWithPopup()
        } catch (err) {
            console.log(err)
        }
    }

    const logout = () => {
        auth0Logout({ logoutParams: { returnTo: window.location.origin }})
        setIsLoggedIn(false)
        setUser(null)
    }

    return (
        <AuthContext.Provider
            value={{ isLoggedIn, user, login, logout, isLoading }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext)
