import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";

const SignatureModal = (props) => {
  const sigCanvas = useRef();
  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    props.setSignature(URL);
    props.closeModal();
  };

  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        size="lg"
        centered
      >
        <div id="invoiceCapture">
          <div className="justify-content-between align-items-start bg-light w-100 p-4">
            <SignatureCanvas
              penColor="green"
              canvasProps={{
                width: 500,
                height: 200,
                className: "sigCanvas",
              }}
              ref={sigCanvas}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                className="text-red-600 text-base font-medium"
                onClick={() => sigCanvas.current.clear()}
              >
                Elimina
              </button>

              <button
                className="create rounded-lg px-4 py-2 border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-sm md:text-base bg-[#004b8f]"
                onClick={create}
              >
                Deposita Firma
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const SignatureModalClient = (props) => {
  const sigCanvasClient = useRef();
  const create = () => {
    const URL = sigCanvasClient.current.getTrimmedCanvas().toDataURL("image/png");
    props.setSignature(URL);
    props.closeModal();
  };

  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        size="lg"
        centered
      >
        <div id="invoiceCaptureClient">
          <div className="justify-content-between align-items-start bg-light w-100 p-4">
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 500,
                height: 200,
                className: "sigCanvas",
              }}
              ref={sigCanvasClient}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                className="text-red-600 text-base font-medium"
                onClick={() => sigCanvasClient.current.clear()}
              >
                Elimina
              </button>

              <button
                className="create rounded-lg w-32 h-8 border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-sm md:text-base bg-[#004b8f]"
                onClick={create}
              >
                Deposita Firma
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export {SignatureModal, SignatureModalClient};
