import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "react-bootstrap";

import { useCreateClient } from "../../services/client";
import { IS_PRODUCTION } from "utils/consts";

const mockValue = {
  name: "Azienda",
  description: "Descrizione di questa azienda",
  address: "Via Test 25",
  cap: "00291",
  city: "Roma",
  phone: "+393515891233",
  email: "test@testing.test",
};

const Label = (props) => {
  const { text, required } = props;

  return (
    <Form.Label>
      {text}
      {required && <span className="text-danger">*</span>}
    </Form.Label>
  );
};

const CreateClientModal = (props) => {
  const { show, handleClose, onCreateComplete } = props;

  const { createClient, loading } = useCreateClient({
    onComplete: (data) => {
      onCreateComplete(data);
      handleClose();
    },
  });

  const { register, handleSubmit } = useForm({
    defaultValues: IS_PRODUCTION ? {} : mockValue,
  });

  const onSubmit = async (data) => {
    await createClient(data);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Aggiungi un Nuovo Cliente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <Label text="Nominativo/Denominazione Cliente : " />
            <Form.Control
              autoFocus
              type="text"
              placeholder="Inserisci denominazione..."
              {...register("name")}
            />
          </div>

          <div className="mb-3">
            <Label text="Descrizione" />
            <Form.Control
              type="textarea"
              placeholder="Inserisci descrizione..."
              {...register("description")}
            />
          </div>

          <div className="mb-3">
            <Label text="Telefono" />
            <Form.Control
              type="number"
              placeholder="Inserisci telefono..."
              {...register("phone")}
            />
          </div>

          <div className="mb-3">
            <Label text="Email" />
            <Form.Control
              type="email"
              placeholder="Inserisci email..."
              {...register("email")}
            />
          </div>

          <div className="mb-3">
            <Label required text="Indirizzo" />
            <Form.Control
              type="textarea"
              placeholder="Inserisci indirizzo..."
              {...register("address", { required: true})}
            />
          </div>

          <div className="mb-3">
            <Label required text="Città" />
            <Form.Control
              type="text"
              placeholder="Inserisci città..."
              {...register("city", { required: true })}
            />
          </div>

          <div className="mb-3">
            <Label required text="CAP" />
            <Form.Control
              type="number"
              placeholder="Inserisci CAP..."
              {...register("cap", {required: true})}
            />
          </div>

          <div className="div-row mt-2">
            <Button
              type="button"
              disabled={loading}
              variant="secondary"
              onClick={handleClose}
              className="bg-gray-700"
            >
              Chiudi
            </Button>
            <Button
              type="submit"
              disabled={loading}
              variant="primary"
              className="bg-blue-700"
            >
              {loading ? "Invio in corso..." : "Salva modifiche"}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

CreateClientModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCreateComplete: PropTypes.func.isRequired,
};

export default CreateClientModal;
