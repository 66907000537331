import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"
const firebaseConfig = {
    apiKey: "AIzaSyD2lBX4AaIH27TYI8_kRKE94iaHxrhkT20",
    authDomain: "deltat-technology.firebaseapp.com",
    projectId: "deltat-technology",
    storageBucket: "deltat-technology.appspot.com",
    messagingSenderId: "607555527483",
    appId: "1:607555527483:web:87c21e991a8c5549df0d1e"
  };

export const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
