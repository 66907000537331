import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "react-bootstrap";

import { useEditClient } from "../../services/client";
import SelectClients from "./SelectClients";

const Label = (props) => {
  const { text, required } = props;

  return (
    <Form.Label>
      {text}
      {required && <span className="text-danger">*</span>}
    </Form.Label>
  );
};

const EditClientModal = (props) => {
  const { show, handleClose, onEditComplete } = props;

  const [client, setClient] = useState(null);

  const { putUpdateClientById, loading } = useEditClient({
    onComplete: (data) => {
      onEditComplete(data);
      handleClose();
    },
  });

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (client) {
      setValue("id", client.value);
      setValue("name", client.name || "");
      setValue("description", client.description || "");
      setValue("phone", client.phone || "");
      setValue("email", client.email.toString() || "");
      setValue("address", client.address || "");
      setValue("city", client.city || "");
      setValue("cap", client.cap || "");
    }
  }, [client, setValue]);

  const onSubmit = async (data) => {
    await putUpdateClientById(data);
  };

  const onChange = (data) => {
    /* console.log(data); */
    setClient(data);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modifica cliente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectClients value={client} onChange={onChange} hide="yes" />
          {client && (
            <>
              <div className="mb-3">
                <Label required text="Nominativo/Denominazione Cliente :" />
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder="Inserisci denominazione..."
                  {...register("name", {required: true})}
                />
              </div>

              <div className="mb-3">
                <Label required text="Descrizione" />
                <Form.Control
                  autoFocus
                  as="textarea"
                  placeholder="Inserisci descrizione..."
                  {...register("description", {required: true})}
                />
              </div>

              <div className="mb-3">
                <Label required text="Telefono" />
                <Form.Control
                  type="number"
                  placeholder="Inserisci telefono..."
                  {...register("phone", {required: true})}
                />
              </div>

              <div className="mb-3">
                <Label required text="Email" />
                <Form.Control
                  type="email"
                  placeholder="Inserisci email..."
                  {...register("email", {required: true})}
                />
              </div>

              <div className="mb-3">
                <Label required text="Indirizzo" />
                <Form.Control
                  type="textarea"
                  placeholder="Inserisci indirizzo..."
                  {...register("address", {required: true})}
                />
              </div>

              <div className="mb-3">
                <Label required text="Città" />
                <Form.Control
                  type="text"
                  placeholder="Inserisci città..."
                  {...register("city", {required: true})}
                />
              </div>

              <div className="mb-3">
                <Label required text="CAP" />
                <Form.Control
                  type="number"
                  placeholder="Inserisci CAP..."
                  {...register("cap", {required: true})}
                />
              </div>
            </>
          )}

          <div className="div-row mt-2">
            <Button
              type="button"
              disabled={loading}
              variant="secondary"
              onClick={handleClose}
              className="bg-gray-700"
            >
              Chiudi
            </Button>
            <Button
              type="submit"
              disabled={loading}
              variant="primary"
              className="bg-blue-700"
            >
              {loading ? "Invio in corso..." : "Salva modifiche"}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

EditClientModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onEditComplete: PropTypes.func.isRequired,
};

export default EditClientModal;
