import React from "react";

import LoginPage from "pages/LoginPage/LoginPage";
import InvoicePage from "pages/InvoicePage/InvoicePage";

import { useAuthContext } from "context/auth-context";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = (props) => {
  const { isLoggedIn, logout } = useAuthContext();

  return (
    <div className="App">
      {isLoggedIn ? <InvoicePage /> : <LoginPage />}

      {isLoggedIn && (
          <button
            className="text-center text-white text-xl self-center flex justify-center bg-gray-600 hover:bg-[#004b8f] py-3 px-6 rounded-xl m-auto mb-4"
            style={{ cursor: "pointer" }}
            onClick={logout}
          >
            Logout
          </button>
      )}
    </div>
  );
};

export default App;
