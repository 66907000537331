import React, { Fragment } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import InvoiceItem from "./InvoiceItem";
import InvoiceModal from "./InvoiceModal";
import AdminModal from "./AdminModal";
import SelectClients from "pages/InvoicePage/components/Clients/SelectClients";

import RemoveClient from "pages/InvoicePage/components/Clients/RemoveClient";
import RemoveProduct from "pages/InvoicePage/components/Products/RemoveProduct";
import EditClient from "pages/InvoicePage/components/Clients/EditClient";
import EditProduct from "pages/InvoicePage/components/Products/EditProduct";

import "bootstrap/dist/css/bootstrap.min.css";
import { IS_PRODUCTION } from "utils/consts";
import { SignatureModal } from "./SignatureModal";
import axiosInstance from "utils/axiosInstance";

import { storage } from "../firebase/firebase";
import { ref, uploadBytesResumable } from "@firebase/storage";

const mockData = {
  invoice_number: 1,
  date_of_issue: "2023-04-23",
  notes: "Notes",

  billTo: null,
  billToOptions: [],

  bill_from: "Company 1",
  bill_from_email: "test@hello.com",
  bill_from_address: "123 Fake Street",
  bill_from_postal_code: "12345",

  city: "Rome",
  province: "",
  telephone: "2345678",
  vat_number: "34567890",
  tax_id_code: "9876543",
  recipient_code: "123",
  ticket_number: "34567",

  currency: "€",
  total: "0.00",
  sub_total: "0.00",
  tax_rate: "",
  tax_ammount: "0.00",
  discount_rate: "",
  discount_ammount: "0.00",
};

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = IS_PRODUCTION
      ? {
          images: [],
          cost: [],
          motivo_chiamata: "",
          modello: "",
          serial_number: "",
          citta: "",
          provincia: "",
          telefono: "",
          indirizzo_email: "",
          numero_ticket: "",
          operatore: null,
          ticket: null,
          user: this.props.user,
          imageURL: null,
          imageURLClient: null,
          isOpen: false,
          isAdminOpen: false,
          isSigOpen: false,
          isSigOpenClient: false,
          isValidItems: false,
          step: 1,
          invoice_number: null,
          date_of_issue: "",
          date_of_start: "",
          time_of_start: "",
          work_description: "",
          date_of_end: "",
          time_of_end: "",
          work_recommendations: "",
          esito_collaudo: "",
          firma_da_tastiera: false,
          nome_cliente: "",
          cognome_cliente: "",
          note_chiusura_intervento:"",
          note_collaudo:"",
          notes: "",
          billTo: null,
          billToOptions: [],
          bill_from: "",
          bill_from_email: "",
          bill_from_address: "",
          currency: "€",
          total: "0.00",
          sub_total: "0.00",
          items: [
            {
              id: "tuPJ8kJLq2TWQuyAQBmz",
              name: "",
              description: "",
              price: "0.00",
              quantity: 0,
            },
          ],
          pending: []
        }
      : mockData;

    this.editField = this.editField.bind(this);
  }

  componentDidMount() {
    this.generateInvoiceNumber();
    this.handleCalculateTotal();
    
    console.log(this.state.user);
  }

  generateInvoiceNumber = async () => {
    try {
      const { data } = await axiosInstance.get("/invoice/generate-number");
      this.setState({
        invoice_number: data.number,
      });
    } catch (err) {
      console.error(err.response);
    }
  };

  isValidItems = (items) => {
    this.setState({
      isValidItems:
        Array.isArray(items) &&
        items.length > 0 &&
        items.every(
          (item) =>
            item.name && item.price && item.quantity && item.id.length > 2
        ),
    });
  };

  handleRowDel(items) {
    const updatedItems = this.state.items.filter(
      (item) => item.id !== items.id
    );
    this.setState({
      items: updatedItems,
    });
  }

  handleAddEvent(evt) {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "1.00",
      description: "",
      quantity: 1,
    };
    const updatedItems = [...this.state.items, items];
    this.setState({ items: updatedItems });
  }

  handleCalculateTotal() {
    var items = this.state.items;
    this.setState({
      total: items
        .map(function (item) {
          /* console.log(item); */
          return parseFloat(item.price) * parseInt(item.quantity);
        })
        .reduce((a, b) => a + b, 0)
        .toFixed(2),
      sub_total: items
        .map(function (item) {
          /* console.log(item); */
          return parseFloat(item.price) * parseInt(item.quantity);
        })
        .reduce((a, b) => a + b, 0)
        .toFixed(2),
    });
  }
  modifyChildState = () => {
    this.setState({step:1})
  }

  onItemizedItemEdit(evt) {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var items = this.state.items.slice();
    var newItems = items.map(function (items) {
      for (var key in items) {
        if (key === item.name && items.id === item.id) {
          items[key] = item.value;
        }
      }
      return items;
    });
    this.setState({ items: newItems });
    this.handleCalculateTotal();
  }

  editField = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.handleCalculateTotal();
  };

  updateItems = (items) => {
    this.setState({ items: items });
  };
  onCurrencyChange = (selectedOption) => {
    this.setState(selectedOption);
  };

  handleChangeClient = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    this.setState({
      id: selectedOption.id,
      billTo: selectedOption,
      denominazione: selectedOption.name,
      citta: selectedOption.city,
      indirizzo_email: selectedOption.email,
      telefono: selectedOption.telephone,
      cap: selectedOption.cap,
      via: selectedOption.address,
    });
  };

  handleChangeOperatore = (selectedOption) => {
    this.setState({
      operatore: selectedOption,
    });
  };

/*   validateStep1 = () => {
    const { invoice_number, date_of_issue, billTo, operatore } = this.state;
    return invoice_number && date_of_issue && billTo && operatore;
  }; */
  validateStep1 = () => {
    const { invoice_number, billTo, operatore } = this.state;
    return invoice_number && billTo && operatore;
  };

  validateStep2 = () => {
    const { date_of_issue, motivo_chiamata } = this.state;
    return date_of_issue && motivo_chiamata !== "";
  };
  validateStep3 = () => {
    const { date_of_start, time_of_start, work_description, date_of_end , time_of_end, work_recommendations } = this.state;
    return date_of_start && time_of_start !== "" && work_description !== "" && date_of_end && time_of_end !== "" && work_recommendations !== "";
  };

  validateStep4 = () => {
    const { currency, sub_total, total, isValidItems, esito_collaudo } = this.state;
    return currency && sub_total && total && isValidItems && esito_collaudo !== "";
  };

  openSigModal = () =>
    this.setState({
      isSigOpen: true,
    });
  openSigModalClient = () =>
    this.setState({
      isSigOpenClient: true,
    });

  validateForm = () => {
    const { imageURL, date_of_closed_issue, note_chiusura_intervento } = this.state;

    return imageURL && date_of_closed_issue && note_chiusura_intervento !== "";
  };

  validateFormPending = () => {
    const { pending } = this.state;
    return pending.length>0;
  };

  openModal = (event) => {
    event.preventDefault();

    if (!this.validateForm()) return;
    /*var newItems = this.state.pending.map(function (items) {
      for (var key in items) {
        console.log(key)
      }
    });*/
    var { pending } = this.state;
    //console.log(pending);
    if (pending.length>0) {
      pending = [...this.state.pending, this.state]
      console.log(pending)
      localStorage.removeItem('pending');
      pending.forEach(element => {
        console.log(element)
        element.imgURL = pending[pending.length-1].imageURL;
        //this.downloadInvoicePDF(element)
        setTimeout(() => {
        this.sendInvoiceToMail(element)
        }, 1000)
      }); 
      alert("Invoices sent to mail!");
      this.setState({ step: 1, invoice_number: (parseInt(this.state.invoice_number) + 1).toString(), date_of_issue: "", operatore: null, ticket: null });
    } else {
    this.handleCalculateTotal();
    this.setState({ isOpen: true });
    }
  };

  downloadInvoicePDF = async (props) => {
    console.log("downloadInvoicePDF props : ", props);
    let reqData = {
      iva: props.iva,
      c_a_p: props.c_a_p,
      telefono: props.telefono,
      provinica: props.provinica,
      codice_fiscale: props.codice_fiscale,
      codice_destinatario: props.codice_destinatario,
      citta: props.citta,
      bill_from: props.denominazione,
      invoice_number: props.invoice_number,
      client_id: props.billTo?.value,
      //bill_from_address: props.billTo?.address,
      bill_from_address: props.via,
      bill_from_email: props.indirizzo_email,
      date_of_issue: props.date_of_issue,
      products: props.items.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
        description: item.descrizione_ricambio,
      })),
      currency: props.currency,
      sub_total: props.sub_total,
      tax_ammount: props.tax_ammount,
      discount_ammount: props.discount_ammount,
      total: props.total,
      imgURL: props.imgURL,
      notes: props.note_collaudo,
      operatore: props.operatore,
      ticket: props.ticket
    };

    //setDownloading(true);

    try {
      const { data } = await axiosInstance.post("/mail/pdf", reqData);
      const pdfData = new Uint8Array(data.fileOriginal.data);
      const blob = new Blob([pdfData], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `rapporto-tecnico-${props.invoice_number}.pdf`;
      link.click();
    } catch (err) {
      console.error(err.response);

      let content = "Something went wrong";

      if (err.response) {
        const { data } = err.response;
        content = data.msg;
      }
      alert(content);
    }
    //setDownloading(false);
  };

  sendInvoiceToMail = async (props) => {
    //setSendingMail(true);
    console.log("sendInvoiceToMail props:", props);
    let data = {
      iva: props.iva,
      c_a_p: props.c_a_p,
      telefono: props.telefono,
      provinica: props.provinica,
      codice_fiscale: props.codice_fiscale,
      codice_destinatario: props.codice_destinatario,
      citta: props.citta,
      bill_from: props.denominazione,
      invoice_number: props.invoice_number,
      client_id: props.billTo?.value,
      bill_from_address: props.via,
      bill_from_email: props.indirizzo_email,
      date_of_issue: props.date_of_issue,
      products: props.items.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
        description: item.descrizione_ricambio,
      })),
      currency: props.currency,
      sub_total: props.sub_total,
      tax_ammount: props.tax_ammount,
      discount_ammount: props.discount_ammount,
      total: props.total,
      imgURL: props.imgURL,
      notes: props.note_collaudo,
      images: props.images,
      operatore: props.operatore,
      ticket: props.ticket
    };

    try {
      if (data.images) {
        let promises = [];

        for (const image of data.images) {
          promises.push(this.uploadImageAsPromise(image));
        }

        const images = await Promise.all(promises);
        data.images = images;
      }

      // await axios.post("/mail/pdf", data);
      await new Promise(resolve => setTimeout(resolve, 3000));
      await axiosInstance.post("/mail/invoice", data);
      //alert("Invoices sent to mail!");
      //setSendingMail(false);
      //navigate(0);
    } catch (err) {
      console.log("OPS", err);
      console.error(err.response);

      let content = "Something went wrong";

      if (err.response) {
        const { data } = err.response;
        content = data.msg;
      }
      //setSendingMail(false);
      alert(content);
    }
  };

  async uploadImageAsPromise(imageFile) {
    return new Promise(function (resolve, reject) {
      const storageRef = ref(storage, `/images/${imageFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      uploadTask.on(
        "state_changed",
        function progress(snapshot) {
          const percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },

        function error(err) {
          reject(err);
        },

        async function complete() {
          // const imageURL = await getDownloadURL(uploadTask.snapshot.ref.name);
          resolve(uploadTask.snapshot.ref.name);
        }
      );
    });
  }

  closeModal = () => this.setState({ isOpen: false });
  closeAdminModal = () => this.setState({ isAdminOpen: false });
  // CHIUSURA DELLE FIRME DELL'OPERATORE E DEL CLIENTE | CLOSING OF THE SIGNATURE OF THE OPERATOR AND OF THE CLIENT
  closeSigModal = () => this.setState({ isSigOpen: false });
  closeSigModalClient = () => this.setState({ isSigOpenClient: false });
  setSignature = (URL) => this.setState({ imageURL: URL });
  setSignatureClient = (URL) => this.setState({ imageURLClient: URL });

  showFirmaDaTastiera = (check) => this.setState({ firma_da_tastiera: check});
  render() {
    return (
      <Fragment>
        <Form>
          <Row>
            {/* ADMIN CONTROL PANEL */}
            {this.state.user &&
              (this.state.user.user.email === "info@macoweb.eu" ||
                this.state.user.user.email ===
                  "gianpiero.varisco@deltat-technology.it" || this.state.user.user.email === "davidochoa.dev@gmail.com") ? (
                <div className="bg-white rounded-xl border-gray-950 border mt-6 justify-center p-4">
                  <h1 className="text-center font-bold text-sm md:text-2xl text-[#54595e]">
                    Benvenuto, {this.state.user.user.name}!
                  </h1>
                  <div className="py-4">
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => this.setState({ isAdminOpen: true })}
                      className="d-block w-1/3 bg-[#004b8f] border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] m-auto text-xs md:text-base min-w-[150px]"
                    >
                      Controlla Archiviazione
                    </Button>
                    <div className="flex flex-row gap-2 md:space-x-4 justify-center flex-wrap items-center mt-4">
                      <EditClient
                          value={this.state.billTo}
                          onChange={this.handleChangeClient}
                      />
                      <RemoveClient
                        value={this.state.billTo}
                        onChange={this.handleChangeClient}
                      />
                      <RemoveProduct
                        value={this.state.billTo}
                        onChange={this.handleChangeClient}
                      />

                      <EditProduct
                        value={this.state.billTo}
                        onChange={this.handleChangeClient}
                      />
                    </div>
                  </div>
                </div>
              ):(
                <div className="bg-white rounded-xl border-gray-950 border mt-6 justify-center">
                  <h1 className="text-center my-3 font-bold text-2xl">
                    Benvenuto, {this.state.user.user.name}!
                  </h1>
                </div>
              )}
            {/* FORM SECTION */}
            <Card className="p-4 p-xl-5 my-3 my-xl-4 h-max">
              {this.state.step === 1 ? (
                <div className="d-flex flex-column align-items-baseline justify-content-between">
                  <div className="text-center my-4 border-b w-full">
                    <h2 className="fw-bold text-md lg:text-3xl pb-4 text-[#54595e]">Dati Cliente</h2>
                  </div>
                  <div className="d-flex flex-row justify-content-between mb-2 align-items-center w-100">
                    <div className="d-flex mr-4">
                      <span className="fw-bold ml-1 text-sm md:text-base text-[#54595e]">Data di&nbsp;Oggi:&nbsp;</span>
                      <span className="current-date">
                        {new Date().toLocaleDateString()}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="fw-bold me-2 text-sm md:text-base text-[#54595e]">
                        N°Rapporto:
                      </span>
                      <div>{this.state.invoice_number}</div>
                    </div>
                  </div>
{/*                   <div className="d-flex flex-row justify-content-between align-items-center w-100">
                    <div className="d-flex">
                      <div className="fw-bold d-block me-2 mt-2 text-sm md:text-base text-[#54595e]">&nbsp;Numero Ticket:</div>
                      <Form.Control
                          value={this.state.ticket}
                          name={"ticket"}
                          onChange={(event) => this.editField(event)}
                          required="required"
                          className="max-w-max"
                          placeholder="Inserisci numero ticket..."
                        />
                    </div>
                  </div> */}
                </div>
              ) : (
                <div className="flex flex-row gap-2 items-center justify-start absolute " m="auto" >
                  <div className=" w-[50px] h-[50px] bg-[#004b8f] text-white rounded-full text-center flex items-center justify-center shadow-md">{this.state.step}/6</div>
                </div>

              )}
              {(this.state.step === 1 || this.state.step === 2) && (
                <hr className="my-4" />
              )}
              <Row className="">
                <Col>
                  {this.state.step === 1 && (
                    <>
                      <SelectClients
                        value={this.state.billTo}
                        onChange={this.handleChangeClient}
                      />
                      <Form.Label className="fw-bold text-sm md:text-base text-[#54595e]">Tecnico:</Form.Label>
                      <div style={{ marginBottom: 10 }}>
                        <Form.Control
                          value={this.state.operatore}
                          name={"operatore"}
                          onChange={(event) => this.editField(event)}
                          required="required"
                          placeholder="Inserisci Nominativo del Tecnico..."
                        />
                      </div>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() =>
                          this.setState({
                            step: this.state.step + 1,
                          }, () => {
                            localStorage.removeItem('pending');
                          })
                        }
                        className="d-block w-100 bg-[#004b8f] mt-4 disabled:bg-[#004b8f] border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-sm md:text-base"
                        disabled={!this.validateStep1()}
                      >
                        Avanti
                      </Button>
                    </>
                  )}{" "}
                  {this.state.step === 2 && (
                    <>
                      {/* DATA DELLA CHIAMATA */}
                      <div className="text-center my-4">
                        <h2 className="fw-bold text-lg lg:text-3xl">Dati Chiamata</h2>
                      </div>
                      <div className="d-flex mr-4 my-3">
                        <div className="fw-bold d-block me-2 mt-2">Data Chiamata</div>
                        <p className="text-red-700 flex mt-2 mr-2">**:</p>
                        <Form.Control
                          type="date"
                          value={this.state.date_of_issue}
                          name={"date_of_issue"}
                          onChange={(event) => this.editField(event)}
                          style={{
                            maxWidth: "150px",
                          }}
                          required="required"
                        />
                      </div>
                      <hr/>
                      {/* MOTIVO DELLA CHIAMATA*/}
                      <div className="flex flex-row space-x-1 mt-2">
                        <Form.Label className="fw-bold flex">
                          Motivo della Chiamata
                        </Form.Label>
                        <p className="text-red-700 flex">**:</p>
                      </div>
                      <Form.Control
                        name="motivo_chiamata"
                        value={this.state.motivo_chiamata}
                        onChange={(event) => this.editField(event)}
                        as="textarea"
                        placeholder="Inserisci motivo della chiamata..."
                        className="h-12 my-2 border border-gray-700 "
                        rows={1}
                      />
                      <hr/>
                      <Form.Label className="fw-bold mt-2">
                        Modello :
                      </Form.Label>
                      <Form.Control
                        name="modello"
                        value={this.state.modello}
                        onChange={(event) => this.editField(event)}
                        as="textarea"
                        className="h-12 my-2 border border-gray-700"
                        placeholder="Inserisci modello..."
                        rows={1}
                      />
                      <hr/>
                      <Form.Label className="fw-bold mt-2">
                        Serial Number :
                      </Form.Label>
                      <Form.Control
                        name="serial_number"
                        value={this.state.serial_number}
                        onChange={(event) => this.editField(event)}
                        as="textarea"
                        placeholder="Inserisci Serial Number..."
                        className="h-12 my-2 border border-gray-700"
                        rows={1}
                      />
                      <hr/>
                      <div className="flex justify-center space-x-8 space-y-6">
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={() =>
                            this.setState({
                              step: this.state.step - 1,
                            })
                          }
                          style={{
                            width: 100,
                            display: "flex",
                          }}
                          className="my-2 text-center justify-center bg-gray-700"
                        >
                          Indietro
                        </Button>
                        <Button
                          variant="primary"
                          type="button"
                          onClick={() =>
                            this.setState({
                              step: this.state.step + 1,
                            })
                          }
                          style={{
                            width: 100,
                            display: "flex",
                          }}
                          disabled={!this.validateStep2()}
                          className="text-center justify-center bg-[#004b8f] my-2 disabled:bg-[#004b8f] border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-sm md:text-base"
                        >
                          Avanti
                        </Button>
                      </div>
                    </>
                  )}

                </Col>
              </Row>
              {this.state.step === 3 && (
                <>
                      <div className="text-center my-4">
                        <h2 className="fw-bold text-lg lg:text-3xl">Descrizione Lavoro Svolto</h2>
                      </div>
                      {/* DATA INIZIO LAVORI */}
                      <div className="d-flex mr-4 my-3">
                        <div className="fw-bold d-block me-2 pt-2">Data Inizio Lavori</div>
                        <p className="text-red-700 flex mr-2 pt-2">**:</p>
                        <Form.Control
                          type="date"
                          value={this.state.date_of_start}
                          name={"date_of_start"}
                          onChange={(event) => this.editField(event)}
                          style={{
                            maxWidth: "150px",
                          }}
                          required="required"
                        />
                        <Form.Control
                          type="time"
                          value={this.state.time_of_start}
                          name={"time_of_start"}
                          className="mx-2"
                          onChange={(event) => this.editField(event)}
                          style={{
                            maxWidth: "150px",
                          }}
                          required="required"
                        />
                      </div>
                      <hr/>
                      {/* DESCRIZIONE LAVORO SVOLTO */}
                      <div className="flex flex-row space-x-1 pt-2">
                        <Form.Label className="fw-bold flex">
                          Descrizione Lavoro Svolto
                        </Form.Label>
                        <p className="text-red-700 flex">**:</p>
                      </div>
                      <Form.Control
                        name="work_description"
                        value={this.state.work_description}
                        onChange={(event) => this.editField(event)}
                        as="textarea"
                        placeholder="Inserisci descrizione del lavoro svolto..."
                        className="h-32 my-2 border border-gray-700 mb-4"
                        rows={1}
                      />
                      <hr/>
                      {/* DATA FINE LAVORI */}
                      <div className="d-flex mr-4 my-3">
                        <div className="fw-bold d-block me-2 mt-2">Data Fine Lavori</div>
                        <p className="text-red-700 flex mr-2 mt-2">**:</p>
                        <Form.Control
                          type="date"
                          value={this.state.date_of_end}
                          name={"date_of_end"}
                          onChange={(event) => this.editField(event)}
                          style={{
                            maxWidth: "150px",
                          }}
                          required="required"
                        />
                        <Form.Control
                          type="time"
                          value={this.state.time_of_end}
                          name={"time_of_end"}
                          className="mx-2"
                          onChange={(event) => this.editField(event)}
                          style={{
                            maxWidth: "150px",
                          }}
                          required="required"
                        />
                      </div>
                      <hr/>
                      {/* RACCOMANDAZIONI */}
                      <div className="flex flex-row space-x-1 pt-4">
                        <Form.Label className="fw-bold flex">
                          Raccomandazioni
                        </Form.Label>
                        <p className="text-red-700 flex">**:</p>
                      </div>
                      <Form.Control
                        name="work_recommendations"
                        value={this.state.work_recommendations}
                        onChange={(event) => this.editField(event)}
                        as="textarea"
                        placeholder="Inserisci le raccomandazioni..."
                        className="h-32 my-1 border border-gray-700 mb-4"
                        rows={1}
                      />
                  <div className="flex justify-center space-x-8 space-y-6">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() =>
                        this.setState({
                          step: this.state.step - 1,
                        })
                      }
                      style={{
                        marginTop: 15,
                        width: 100,
                      }}
                      className="bg-gray-700 my-2 text-center justify-center flex"
                    >
                      Indietro
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() =>
                        this.setState({
                          step: this.state.step + 1,
                        })
                      }
                      style={{
                        width: 100,
                        marginTop: 15,
                      }}
                      disabled={!this.validateStep3()}
                      className=" my-2 text-center justify-center flex bg-[#004b8f] disabled:bg-[#004b8f] border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-sm md:text-base"
                    >
                      Avanti
                    </Button>
                  </div>
                </>
              )}
              {this.state.step === 4 && (
                <>
                  <div className="flex justify-center flex-col text-center">
                    <h2 className="text-lg lg:text-3xl font-semibold my-2">Aggiungi Immagine</h2>

                    {this.state.images.length > 0
                      ? this.state.images.map((image, index) => {
                          return (
                            <div
                              key={index}
                              className="justify-center flex flex-col"
                            >
                              <img
                                className="m-auto my-4 rounded-md"
                                alt="not found"
                                width={"250px"}
                                src={URL.createObjectURL(image)}
                              />
                              <br />
                              <button
                                className="w-24 bg-red-600 text-base font-medium text-white m-auto rounded-lg h-12"
                                onClick={() =>
                                  this.setState({
                                    images: this.state.images.filter(
                                      (img) => img !== image
                                    ),
                                  })
                                }
                              >
                                Rimuovi
                              </button>
                            </div>
                          );
                        })
                      : (this.state.images.length === 0) && (
                        <img 
                          className="m-auto my-4 rounded-md"
                          alt="Empty Img"
                          width={"250px"}
                          src={"https://icon-library.com/images/img-icon/img-icon-0.jpg"}
                        />
                      )}

                    <br />
                    <br />

                    <input
                      multiple
                      accept="image/*"
                      type="file"
                      id="fileInput"
                      name="myImage"
                      onChange={(event) => {
                        const images = this.state.images || [];
                        const newImages = Array.from(event.target.files);
                        this.setState({
                          images: images.concat(newImages),
                        });
                      }}
                      className="hidden"
                    />
                    <div className="d-flex align-items-center w-40 h-10 bg-[#004b8f] text-white rounded-lg flex justify-center text-center p-4 mb-4 m-auto">
                      <i class="fas fa-camera mr-2"></i>
                      <label
                        for="fileInput"
                        className="flex self-center flex-nowrap "
                      >
                        Scatta foto
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-center space-x-8 space-y-6">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() =>
                        this.setState({
                          step: this.state.step - 1,
                        })
                      }
                      style={{
                        marginTop: 15,
                        width: 100,
                      }}
                      className="bg-gray-700 my-2 text-center justify-center flex"
                    >
                      Indietro
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() =>
                        this.setState({
                          step: this.state.step + 1,
                        })
                      }
                      style={{
                        width: 100,
                        marginTop: 15,
                      }}
                      disabled={!this.validateStep2()}
                      className=" my-2 text-center justify-center flex bg-[#004b8f] disabled:bg-[#004b8f] border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-sm md:text-base"
                    >
                      Avanti
                    </Button>
                  </div>
                </>
              )}
              {this.state.step === 5 && (
                <>
                  <div className="text-center my-4 ">
                    <h2 className="fw-bold text-lg lg:text-3xl text-[#54595e]">Materiale Utilizzato & Collaudo</h2>
                  </div>
                  <InvoiceItem
                    onItemizedItemEdit={this.onItemizedItemEdit.bind(this)}
                    onRowAdd={this.handleAddEvent.bind(this)}
                    onRowDel={this.handleRowDel.bind(this)}
                    currency={this.state.currency}
                    items={this.state.items}
                    isValidItems={this.isValidItems}
                    updateItems={this.updateItems}
                  />
                  <hr className="my-4" />
                  <Form.Label className="fw-bold">Note collaudo:</Form.Label>
                  <Form.Control
                    placeholder="Inserisci note collaudo..."
                    name="note_collaudo"
                    value={this.state.note_collaudo}
                    onChange={(event) => this.editField(event)}
                    as="textarea"
                    className="my-2"
                    rows={1}
                  />
                  <Form.Label className="fw-bold">Esito collaudo:</Form.Label>
                  <Form.Select
                    aria-label="Esito del collaudo"
                    value={this.state.esito_collaudo}
                    onChange={(event) => this.editField(event)}
                    name="esito_collaudo"
                  >
                    <option value = "">Seleziona un esito di collaudo</option>
                    <option value="positivo">Positivo</option>
                    <option value="negativo">Negativo</option>
                  </Form.Select>
                  <div className="flex justify-center space-x-8 space-y-6 my-4">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() =>
                        this.setState({
                          step: this.state.step - 1,
                        })
                      }
                      style={{
                        marginTop: 25,
                        width: 100,
                      }}
                      className="bg-gray-700 my-2 text-center justify-center flex"
                    >
                      Indietro
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => {
                        this.handleCalculateTotal();
                        this.setState({
                          step: this.state.step + 1,
                        });
                      }}
                      style={{
                        width: 100,
                        marginTop: 15,
                      }}
                      disabled={false}
                      className="my-2 text-center justify-center flex bg-[#004b8f] border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-xs md:text-base"
                    >
                      Avanti
                    </Button>
                  </div>
                </>
              )}
              {this.state.step === 6 && (
                <>
                  <div className="text-center my-4">
                    <h2 className="fw-bold text-lg lg:text-3xl">Chiusura Intervento</h2>
                  </div>
                  <Row className="justify-content-end">
                      <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                        <span className="fw-bold">Totale:</span>
                        <span>
                          {this.state.currency} {this.state.sub_total}
                        </span>
                      </div>
                  </Row>
                  <div className="d-flex mr-4 my-3">
                        <div className="fw-bold d-block me-2 mt-2">Data Chiusura Intervento</div>
                        <p className="text-red-700 flex mt-2 mr-2">**:</p>
                        <Form.Control
                          type="date"
                          value={this.state.date_of_closed_issue}
                          name={"date_of_closed_issue"}
                          onChange={(event) => this.editField(event)}
                          style={{
                            maxWidth: "150px",
                          }}
                          required="required"
                        />
                  </div>
                  <div className="flex flex-row space-x-1 pt-4">
                        <Form.Label className="fw-bold flex">
                          Note Chiusura Intervento
                        </Form.Label>
                        <p className="text-red-700 flex">*:</p>
                      </div>
                      <Form.Control
                        name="note_chiusura_intervento"
                        value={this.state.note_chiusura_intervento}
                        onChange={(event) => this.editField(event)}
                        as="textarea"
                        placeholder="Inserisci le note per la chiusura dell'intervento..."
                        className="h-32 my-2 border border-gray-700 mb-4"
                        rows={1}
                        required="required"
                      />

                  <Row>
                  <Col lg={6} className="my-2">
                  <Form.Check
                    type="switch"
                    id="firma_da_tastiera"
                    name="firma_da_tastiera"
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      this.setState({
                        firma_da_tastiera: isChecked
                      });
                    }} 
                    label="Abilita Firma da Tastiera"
                  />
                  </Col>
                    {this.state.firma_da_tastiera && (
                    <Col lg={6}>
                    <Form.Label className="fw-bold">Nome Cliente :</Form.Label>
                      <div style={{ marginBottom: 10 }}>
                        <Form.Control
                          value={this.state.nome_cliente}
                          name={"nome_cliente"}
                          onChange={(event) => this.editField(event)}
                          placeholder="Inserisci Nome Cliente..."
                        />
                      </div>
                      <Form.Label className="fw-bold">Cognome Cliente :</Form.Label>
                      <div style={{ marginBottom: 10 }}>
                        <Form.Control
                          value={this.state.cognome_cliente}
                          name={"cognome_cliente"}
                          onChange={(event) => this.editField(event)}
                          placeholder="Inserisci Cognome Cliente..."
                        />
                      </div>
                    </Col>
                    )}
                  </Row>

                  {this.state.imageURL && (
                    <>
                      <img
                        style={{
                          width: 150,
                          height: 50,
                          marginTop: 15,
                          marginBottom: 15,
                        }}
                        src={this.state.imageURL}
                        alt="signature"
                        className="signature flex m-auto"
                      />
                    </>
                  )}
                  <Button
                    variant="primary"
                    type="button"
                    style={{
                      width: 200,
                      marginTop: 15,
                      marginBottom: 10,
                    }}
                    onClick={this.openSigModal}
                    className="my-2 text-center justify-center flex m-auto bg-[#004b8f] border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-sm md:text-base"
                  >
                    Aggiungi Firma Cliente
                  </Button>

{/*                   <div className="sticky-top pt-md-3 pt-xl-4 flex justify-center space-x-8 space-y-6">
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => {
                          this.setState({
                            step: 2,
                            invoice_number:  (parseInt(this.state.invoice_number) + 1).toString(),
                            pending:  [...this.state.pending, this.state]
                          }, () => {
                            localStorage.setItem('pending', JSON.stringify(this.state.pending))
                          })
                        }
                      }
                      style={{
                        width: 100,
                      }}
                      className="bg-gray-700 my-2 text-center justify-center flex mx-2"
                    >
                      Firma Dopo
                    </Button>
                  </div> */}
                  <div className="sticky-top pt-md-3 pt-xl-4 flex justify-center space-x-8 space-y-6">
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() =>
                        this.setState({
                          step: this.state.step - 1,
                        })
                      }
                      style={{
                        width: 100,
                      }}
                      className="bg-gray-700 my-2 text-center justify-center flex mx-2 hover:bg-[#004b8f] border-0"
                    >
                      Indietro
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      style={{ width: 100 }}
                      onClick={this.openModal}
                      disabled={!this.validateForm()}
                      className="my-2 text-center justify-center flex mx-2 bg-[#004b8f] disabled:bg-[#004b8f] border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-sm md:text-base"
                    >
                      Ricevuta
                    </Button>
                  </div>
                </>
              )}
            </Card>
          </Row>
        </Form>
        <AdminModal
          showModal={this.state.isAdminOpen}
          closeModal={this.closeAdminModal}
        />
        <InvoiceModal
          showModal={this.state.isOpen}
          closeModal={this.closeModal}
          info={this.state}
          items={this.state.items}
          currency={this.state.currency}
          sub_total={this.state.sub_total}
          tax_ammount={this.state.tax_ammount}
          discount_ammount={this.state.discount_ammount}
          total={this.state.total}
          imgURL={this.state.imageURL}
          imgURLClient={this.state.imageURLClient}
        />
        <SignatureModal
          showModal={this.state.isSigOpen}
          closeModal={this.closeSigModal}
          setSignature={this.setSignature}
        />
{/*         <SignatureModalClient 
          showModal={this.state.isSigOpenClient}
          closeModal={this.closeSigModalClient}
          setSignature={this.setSignatureClient}
        /> */}
      </Fragment>
    );
  }
}

export default InvoiceForm;
