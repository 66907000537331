import React, { useState } from "react";
import axios from "utils/axiosInstance";
import { Row, Col, Button, Table, Modal, Accordion } from "react-bootstrap";
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { storage } from "../firebase/firebase";
import { ref, uploadBytesResumable } from "@firebase/storage";

const InvoiceModal = (props) => {
  const [sendingMail, setSendingMail] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const navigate = useNavigate();

  async function uploadImageAsPromise(imageFile) {
    return new Promise(function (resolve, reject) {
      const storageRef = ref(storage, `/images/${imageFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      uploadTask.on(
        "state_changed",
        function progress(snapshot) {
          const percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },

        function error(err) {
          reject(err);
        },

        async function complete() {
          // const imageURL = await getDownloadURL(uploadTask.snapshot.ref.name);
          resolve(uploadTask.snapshot.ref.name);
        }
      );
    });
  }

  const sendInvoiceToMail = async () => {
    setSendingMail(true);
    let data = {
      client_id: props.info.billTo?.value,
      bill_from: props.info.denominazione,
      bill_from_address: props.info.billTo?.address,
      citta: props.info.citta,
      c_a_p: props.info.cap,
      bill_from_email: props.info.indirizzo_email,
      telefono: props.info.billTo?.phone,
      operatore: props.info.operatore,
      invoice_number: props.info.invoice_number,
      ticket: props.info.ticket,
      iva: props.info.iva,
      provinica: props.info.provinica,
      date_of_issue: props.info.date_of_issue,
      motivo_chiamata: props.info.motivo_chiamata,
      modello: props.info.modello,
      serial_number: props.info.serial_number,
      date_of_start: props.info.date_of_start,
      time_of_start: props.info.time_of_start,
      work_description: props.info.work_description,
      date_of_end: props.info.date_of_end,
      time_of_end: props.info.time_of_end,
      work_recommendations: props.info.work_recommendations,
      esito_collaudo: props.info.esito_collaudo,
      note_collaudo: props.info.note_collaudo,
      date_of_closed_issue: props.info.date_of_closed_issue,
      note_chiusura_intervento: props.info.note_chiusura_intervento,
      products: props.items.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
        description: item.description,
      })),
      currency: props.currency,
      sub_total: props.sub_total,
      tax_ammount: props.tax_ammount,
      discount_ammount: props.discount_ammount,
      total: props.total,
      firma_da_tastiera: props.info.firma_da_tastiera,
      nome_cliente: props.info.nome_cliente,
      cognome_cliente: props.info.cognome_cliente,
      imgURL: props.imgURL,
      notes: props.info.notes,
      images: props.info.images,
    };

    try {
      if (data.images) {
        let promises = [];

        for (const image of data.images) {
          promises.push(uploadImageAsPromise(image));
        }

        const images = await Promise.all(promises);
        data.images = images;
      }

      // await axios.post("/mail/pdf", data);
      console.log("data da inviare:", data);
      await axios.post("/mail/invoice", data);
      alert("Invoice sent to mail!");
      setSendingMail(false);
      navigate(0);
    } catch (err) {
      console.error("err is:", err);
      let content = "Something went wrong";

      if (err.response) {
        const { data } = err.response;
        content = data.msg;
      }
      setSendingMail(false);
      alert(content + " " + err.response);
    }
  };

  const downloadInvoicePDF = async (imgURL) => {
    let reqData = {
      client_id: props.info.billTo?.value,
      bill_from: props.info.denominazione,
      bill_from_address: props.info.billTo?.address,
      citta: props.info.citta,
      c_a_p: props.info.cap,
      bill_from_email: props.info.indirizzo_email,
      telefono: props.info.billTo?.telefono,
      operatore: props.info.operatore,
      invoice_number: props.info.invoice_number,
      ticket: props.info.ticket,
      iva: props.info.iva,
      provinica: props.info.provinica,
      codice_fiscale: props.info.codice_fiscale,
      codice_destinatario: props.info.codice_destinatario,
      date_of_issue: props.info.date_of_issue,
      motivo_chiamata: props.info.motivo_chiamata,
      modello: props.info.modello,
      serial_number: props.info.serial_number,
      date_of_start: props.info.date_of_start,
      time_of_start: props.info.time_of_start,
      work_description: props.info.work_description,
      date_of_end: props.info.date_of_end,
      time_of_end: props.info.time_of_end,
      work_recommendations: props.info.work_recommendations,
      esito_collaudo: props.info.esito_collaudo,
      note_collaudo: props.info.note_collaudo,
      date_of_closed_issue: props.info.date_of_closed_issue,
      note_chiusura_intervento: props.info.note_chiusura_intervento,
      products: props.items.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
        description: item.description,
      })),
      currency: props.currency,
      sub_total: props.sub_total,
      tax_ammount: props.tax_ammount,
      discount_ammount: props.discount_ammount,
      total: props.total,
      firma_da_tastiera: props.info.firma_da_tastiera,
      nome_cliente: props.info.nome_cliente,
      cognome_cliente: props.info.cognome_cliente,
      imgURL: props.imgURL,
      notes: props.info.notes,
      images: props.info.images,
    };

    setDownloading(true);

    try {
      const { data } = await axios.post("/mail/pdf", reqData);
      const pdfData = new Uint8Array(data.fileOriginal.data);
      const blob = new Blob([pdfData], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `delta-t_rapporto-tecnico_${props.info.invoice_number}.pdf`;
      link.click();
    } catch (err) {

      let content = "Something went wrong";

      if (err.response) {
        const { data } = err.response;
        console.log("err",err);
        content = data.msg;
      }
      alert(content);
    }
    setDownloading(false);
  };

  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        size="lg"
        centered
      >
        <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-4">
          <div className="w-75">
            <h6 className="fw-bold text-secondary mb-1">
              Rapporto Tecnico #: {props.info.invoice_number || ""}
            </h6>
          </div>
          <div className="text-end ms-4">
            <h6 className="fw-bold mt-1 mb-2">Totale:</h6>
            <h5 className="fw-bold text-secondary">
              {" "}
              {props.currency} {props.total}
            </h5>
          </div>
        </div>
        <div id="invoiceCapture">
          <div className="p-4">
            <Row className="mb-4">
              <Col md={4}>
                <div className="fw-bold">Cliente:</div>
                <div>{props.info.billTo?.label || ""}</div>
                <div>{props.info.billTo?.address || ""}</div>
                <div>{props.info.billTo?.email || ""}</div>
                <div>{props.info.billTo?.telephone || ""}</div>
              </Col>
            </Row>
            <Accordion className="my-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Dati Chiamata</Accordion.Header>
                <Accordion.Body>
                <Row>
                  <Table className="mb-0 w-full table-fixed table ">
                    <tbody>
                      <tr>
                        <th className="border-r border-l p-2">Data:</th>
                        <td className="text-start border-r border-t" >
                        {new Date(props.info.date_of_issue).toLocaleDateString() || ""}
                        </td>
                      </tr>
                      <tr>
                        <th className="border-r border-l p-2">Motivo Chiamata:</th>
                        <td className="text-start border-r border-t break-words" >{props.info.motivo_chiamata}</td>
                      </tr>
                      <tr>
                        <th className="border-r border-l p-2">Modello:</th>
                        <td className="text-start border-r border-t break-words" >{props.info.modello}</td>
                      </tr>
                      <tr>
                        <th className="border-r border-l p-2">Serial Number:</th>
                        <td className="text-start border-r border-t break-words" >{props.info.serial_number}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Descrizione Lavoro Svolto</Accordion.Header>
                <Accordion.Body>
                <Row className="my-2">
                  <Table className="mb-0 w-full table-fixed table ">
                    <tbody>
                      <tr>
                        <th className="border-r border-l p-2">Inizio Lavori:</th>
                        <td className="text-start border-r border-t" >
                        {new Date(props.info.date_of_start).toLocaleDateString() || ""}
                        {" "}
                        {props.info.time_of_start}
                        </td>
                      </tr>
                      <tr>
                        <th className="border-r border-l p-2">Descrizione Lavoro:</th>
                        <td className="text-start border-r border-t break-words" >{props.info.work_description}</td>
                      </tr>
                      <tr>
                      <td colSpan={2} className="text-start border-r border-l p-2">
                          <div className="grid grid-cols-2 grid-flow-row items-center justify-center">
                          {props.info.images.map((image, index) => {
                            return (
                              <div key={index} className="justify-center">
                                <img
                                  className="m-auto my-4 rounded-md"
                                  alt="not found"
                                  width={"100px"}
                                  src={URL.createObjectURL(image)}
                                />
                              </div>
                            );
                          })}
                          </div>
                      </td>
                      </tr>
                      <tr>
                        <th className="border-r border-l p-2">Fine Lavori:</th>
                        <td className="text-start border-r border-t break-words " >{new Date(props.info.date_of_end).toLocaleDateString() || ""}
                        {" "}
                        {props.info.time_of_end}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Raccomandazioni</Accordion.Header>
                <Accordion.Body>
                <Row>
                  <Table className="mb-0 w-full table-fixed table ">
                    <tbody>
                      <tr>
                        <td className="text-start border p-2 break-words" >
                        {props.info.work_recommendations}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Collaudo</Accordion.Header>
                <Accordion.Body>
                <Row>
                  <Table className="mb-0 w-full table-fixed table ">
                    <tbody>
                      <tr>
                        <th className="border-r border-l p-2 text-center" colSpan="2">Esito collaudo apparecchiatura a fine intervento:</th>
                      </tr>
                      <tr>
                        <td className="text-start border p-2 break-words" colSpan="2">
                          <div className="w-full grid grid-cols-2">
                          <div className=" flex flex-row justify-center items-center gap-2 bg-green-300 p-2">
                          {props.info.esito_collaudo === "positivo" ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square-fill" viewBox="0 0 16 16">
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
                          </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-square" viewBox="0 0 16 16">
                              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            </svg>
                          )} <p className="grow">Positivo</p>
                          </div>
                          <div className=" flex flex-row justify-center items-center gap-2 bg-red-300 p-2">
                          {props.info.esito_collaudo === "negativo" ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                          </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-square" viewBox="0 0 16 16">
                              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            </svg>
                          )} <p className="grow">Negativo</p>
                          </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="2" className="border p-2 text-center">Note Collaudo:</th>
                      </tr>
                      <tr>
                        <td colSpan="2"  className="text-start border break-words p-2" >{props.info.note_collaudo}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Chiusura Intervento</Accordion.Header>
                <Accordion.Body>
                <Row>
                  <Table className="mb-0 w-full table-fixed table ">
                    <tbody>
                      <tr>
                        <th className="border-r border-l p-2">Data Chiusura Intervento:</th>
                        <td className="text-start border-r border-t break-words " >{new Date(props.info.date_of_closed_issue).toLocaleDateString() || ""}</td>
                      </tr>
                      <tr>
                        <th colSpan="2" className="border p-2 text-center">Note Chiusura Intervento:</th>
                      </tr>
                      <tr>
                        <td colSpan="2"  className="text-start border break-words p-2" >{props.info.note_chiusura_intervento}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>Quantità</th>
                  <th>Descrizione</th>
                  <th>Prezzo Unità</th>
                  <th className="text-end" colSpan="2">
                    Prezzo
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.items.map((item, i) => {
                  return (
                    <tr id={i} key={i}>
                      <td style={{ width: "70px" }}>{item.quantity}</td>
                      <td>
                        {item.name}{" "}
                        {item.description ? ` - ${item.description}` : ""}
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        {props.currency} {item.price}
                      </td>
                      <td className="text-end" style={{ width: "100px" }}>
                        {props.currency} {item.price * item.quantity}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Table>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: "100px" }}>
                    SUBTOTALE
                  </td>
                  <td className="text-end" style={{ width: "100px" }}>
                    {props.currency} {props.sub_total}
                  </td>
                </tr>
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: "100px" }}>
                    TOTALE
                  </td>
                  <td className="text-end" style={{ width: "100px" }}>
                    {props.currency} {props.total}
                  </td>
                </tr>
                <tr className="text-center">
                    <th colSpan="3"> Firma del cliente che attesta la conformità del lavoro eseguito ed autorizza la fatturazione </th>
                </tr>
                <tr className="text-end">
                  <td colSpan="3">
                    <img
                      style={{
                        width: 150,
                        height: 50,
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                      src={props.imgURL}
                      alt="signature"
                      className="signature mx-auto"
                    />
                    {props.info.firma_da_tastiera ? (
                    <div className="text-center">
                    <p>{props.info.nome_cliente + " " + props.info.cognome_cliente}</p>
                    </div>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="pb-4 px-4">
          <Row>
            <Col md={4}>
              <Button
                variant="primary"
                className="bg-[#004b8f] w-full mt-1 py-3 hover:bg-[#ffc325] hover:text-[#004b8f]"
                onClick={sendInvoiceToMail}
                disabled={downloading || sendingMail}
              >
                <div className=" flex-row flex justify-center">
                  <BiPaperPlane
                    style={{
                      width: "15px",
                      height: "15px",
                      marginTop: "-3px",
                    }}
                    className="me-2 flex justify-center self-center"
                  />
                  <p className="flex justify-center self-center">
                    {sendingMail ? "Invio email..." : "Invia"}
                  </p>
                </div>
              </Button>
            </Col>
            <Col md={4}>
              <Button
                variant="outline-primary"
                className="w-full mt-1 py-3 hover:bg-[#ffc325] hover:text-[#004b8f]"
                onClick={() => {
                  downloadInvoicePDF(props.imgURL);
                }}
                disabled={downloading || sendingMail}
              >
                <div className=" flex-row flex justify-center">
                  <BiCloudDownload
                    style={{
                      width: "16px",
                      height: "16px",
                      marginTop: "-3px",
                    }}
                    className="me-2 flex justify-center self-center"
                  />
                  <p className="flex justify-center self-center">
                    {downloading ? "Scaricando..." : "Scarica Una Copia"}
                  </p>
                </div>
              </Button>
            </Col>
            <Col md={4}>
              <Button
                variant="secondary"
                className="bg-gray-700 w-full mt-1 py-3"
                onClick={props.closeModal}
                disabled={downloading || sendingMail}
              >
                Chiudi
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default InvoiceModal;
