import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";

import { AUTH0 } from "utils/consts";
import { AuthContextProvider } from "./context/auth-context";
import "./index.scss";

import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);
console.log("redirect_uri :", window.location.origin);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0.DOMAIN}
      clientId={AUTH0.CLIENT_ID}  
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      audience={AUTH0.AUDIENCE}
    >
      <AuthContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthContextProvider>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
