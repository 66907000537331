import React, { useEffect, useState } from "react"
import axios from "utils/axiosInstance"
import { Row, Col, Button, Table, Modal } from "react-bootstrap"
import { FaRegWindowClose, FaFilePdf } from "react-icons/fa"

const InvoiceModal = (props) => {
    const [done, setDone] = useState(false)
    const [files, setFiles] = useState([])
    useEffect(() => {
        const getFiles = async () => {
            let result = await axios.get(`/client/admin`)
            console.log(result.data);
            setFiles(result.data)
            setDone(true)
        }
        getFiles()
    }, [done])
    const removeFile = async (name) => {
        try {
            const path = name.split("/")
            await axios.delete(`/client/remove/${path[1]}`)
            setDone(false)
        } catch (err) {
            console.error(err.response)

            let content = "Something went wrong"

            if (err.response) {
                const { data } = err.response
                content = data.msg
            }
            alert(content)
        }
    }
    const downloadFile = async (name) => {
        try {
            const path = name.split("/")
            await axios.get(`/mail/download/${path[1]}`).then((response) => {
                const data = new Uint8Array(response.data.fileOriginal[0].data)
                const blob = new Blob([data], { type: "application/pdf" })
                const link = document.createElement("a")
                link.href = window.URL.createObjectURL(blob)
                link.download = `${path[1]}.pdf`
                link.click()
            })
        } catch (err) {
            console.error(err.response)

            let content = "Something went wrong"

            if (err.response) {
                const { data } = err.response
                content = data.msg
            }
            alert(content)
        }
    }
    return (
        <div>
            <Modal
                show={props.showModal}
                onHide={props.closeModal}
                size="lg"
                centered
            >
                <div id="invoiceCapture">
                    <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-4">
                        <div className="w-100 justify-between flex">
                            <h6
                                className="fw-bold text-secondary mb-1"
                                style={{ display: "inline-block" }}
                            >
                                invoices/
                            </h6>
                            <FaRegWindowClose onClick={props.closeModal} />
                        </div>
                    </div>
                    <div className="p-4">
                        <Table className="mb-0">
                            <thead>
                                {Array.isArray(files) &&
                                    files.map((file) => {
                                        if (
                                            file.split("/").length === 2 &&
                                            file.split("/")[1].split(".")[1] ===
                                                "pdf"
                                        )
                                            return (
                                                <tr>
                                                    <th className="p-4 flex">
                                                        <div
                                                            className="hover:to-blue-500 hover:text-blue-500 hover:underline flex cursor-pointer"
                                                            onClick={() => {
                                                                downloadFile(
                                                                    file
                                                                )
                                                            }}
                                                        >
                                                            <FaFilePdf className="self-center" />
                                                            <p>
                                                                {
                                                                    file.split(
                                                                        "/"
                                                                    )[1]
                                                                }
                                                            </p>
                                                        </div>
                                                    </th>
                                                    <th
                                                        className="text-end"
                                                        colSpan="2"
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                removeFile(
                                                                    `${file}`
                                                                )
                                                            }}
                                                            className="bg-blue-700"
                                                        >
                                                            Remove
                                                        </Button>
                                                    </th>
                                                </tr>
                                            )
                                    })}
                            </thead>
                        </Table>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default InvoiceModal
