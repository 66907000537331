import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "react-bootstrap";

import { useEditProduct } from "../../services/product";
import SelectProducts from "./SelectProducts";

const Label = (props) => {
  const { text, required } = props;

  return (
    <Form.Label>
      {text}
      {required && <span className="text-danger">*</span>}
    </Form.Label>
  );
};

const EditProductModal = (props) => {
  const { show, handleClose, onEditComplete } = props;

  const [client, setClient] = useState(null);

  const { putUpdateProductById, loading } = useEditProduct({
    onComplete: (data) => {
      onEditComplete(data);
      handleClose();
    },
  });

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (client) {
      console.log("Client:", client)
      setValue("id", client.id);
      setValue("codice_ricambio", client.codice_ricambio || "");
      setValue("descrizione_ricambio", client.descrizione_ricambio || "");
      setValue("qty", client.qty || "");
      setValue("price", client.price || 0);
      setValue("categories", client.categories || "");
      setValue("image", client.image || "");
      setValue("name_attribute_1", client.name_attribute_1 || "");
      setValue("currencies_attribute_1", client.currencies_attribute_1 || "");
      setValue("visible_attribute_1", client.visible_attribute_1 || true);
      setValue("global_attribute_1", client.global_attribute_1 || true);
      setValue("name_attribute_2", client.name_attribute_2 || "");
      setValue("currencies_attribute_2", client.currencies_attribute_2 || "");
      setValue("visible_attribute_2", client.visible_attribute_2 || true);
      setValue("global_attribute_2", client.global_attribute_2 || true);
      setValue("name_attribute_3", client.name_attribute_3 || "");
      setValue("currencies_attribute_3", client.currencies_attribute_3 || "");
    }
  }, [client, setValue]);

  const onSubmit = async (data) => {
    await putUpdateProductById(data);
  };

  const onChange = (data) => {
    setClient(data);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modifica prodotto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectProducts value={client} onChange={onChange} hide="yes" />

          {client && (
            <>
              <div className="mb-3">
                <Label required text="Descrizione" />
                <Form.Control
                  type="textarea"
                  placeholder="Inserisci descrizione..."
                  {...register("descrizione_ricambio")}
                />
              </div>
              <div className="mb-3">
                <Label text="Codice Ricambio" />
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder="Inserisci codice ricambio..."
                  {...register("codice_ricambio")}
                />
              </div>
              <div className="mb-3">
                <Label text="Quantità" />
                <Form.Control
                  type="text"
                  placeholder="Modifica Quantità..."
                  {...register("qty")}
                />
              </div>
              <div className="mb-3">
                <Label text="Prezzo" />
                <Form.Control
                  type="number"
                  placeholder="Inserisci prezzo ..."
                  {...register("price")}
                />
              </div>
            </>
          )}

          <hr />

          <div className="div-row mt-2">
            <Button
              type="button"
              disabled={loading}
              variant="secondary"
              onClick={handleClose}
              className="bg-gray-700"
            >
              Chiudi
            </Button>
            <Button
              type="submit"
              disabled={loading}
              variant="primary"
              className="bg-blue-700"
            >
              {loading ? "Invio in corso..." : "Salva modifiche"}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

EditProductModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onEditComplete: PropTypes.func.isRequired,
};

export default EditProductModal;
