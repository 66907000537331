import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "react-bootstrap";

import { useCreateProduct } from "../../services/product";
import { IS_PRODUCTION } from "utils/consts";

/* const mockValue = {
  name: "Test Product",
  description: "Test",
  warehouse: "Test",
  quantity_low_stock: false,
  price_list: 12,
  categories: "123456",
  image: "123456789",

  name_attribute_1: "hello@test.com",
  currencies_attribute_1: "Test",
  visible_attrubute_1: true,
  global_attribute_1: true,

  name_attribute_2: "hello@test.com",
  currencies_attribute_2: "Test",
  visible_attrubute_2: true,
  global_attribute_2: true,

  name_attribute_3: "hello@test.com",
  currencies_attribute_3: "Test",
}; */

const mockValue = {
  codice_ricambio: "10301312",
  descrizione_ricambio: "Ricambio super strano e super raro",
  price: "100",
  qty: "1"
};

const Label = (props) => {
  const { text, required } = props;

  return (
    <Form.Label>
      {text}
      {required && <span className="text-danger">*</span>}
    </Form.Label>
  );
};

const CreateProductModal = (props) => {
  const { show, handleClose, onCreateComplete } = props;

  const { createProduct, loading } = useCreateProduct({
    onComplete: (data) => {
      onCreateComplete(data);
      handleClose();
    },
  });

  const { register, handleSubmit } = useForm({
    defaultValues: IS_PRODUCTION ? {} : mockValue,
  });

  const onSubmit = async (data) => {
    await createProduct(data);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Aggiungi nuovo prodotto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <Label required text="Codice Ricambio" />
            <Form.Control
              autoFocus
              type="text"
              placeholder="Inserisci Codice Ricambio..."
              {...register("codice_ricambio", { required: true })}
            />
          </div>

          <div className="mb-3">
            <Label required text="Descrizione Ricambio" />
            <Form.Control
              type="textarea"
              placeholder="Inserisci descrizione del ricambio..."
              {...register("descrizione_ricambio", {required: true})}
            />
          </div>

          <div className="mb-3">
            <Label required text="Quantità" />
            <Form.Control
              type="text"
              placeholder="Inserisci la quantità"
              {...register("qty", {required: true})}
            />
          </div>

          <div className="mb-3">
            <Label text="Prezzo" />
            <Form.Control
              type="number"
              placeholder="Inserisci prezzo ..."
              {...register("price")}
            />
          </div>

          <hr />

          <div className="div-row mt-3">
            <Button
              type="button"
              disabled={loading}
              variant="secondary"
              onClick={handleClose}
              className="bg-gray-700"
            >
              Chiudi
            </Button>
            <Button
              type="submit"
              disabled={loading}
              variant="primary"
              className="bg-blue-700"
            >
              {loading ? "Invio in corso..." : "Salva modifiche"}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

CreateProductModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCreateComplete: PropTypes.func.isRequired,
};

export default CreateProductModal;
