import React, { useState } from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import { Form, Button } from "react-bootstrap";

import CreateClientModal from "./CreateClientModal";
import { useGetClients } from "../../services/client";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 35,
  }),
};

const SelectClients = (props) => {
  const { onChange, value } = props;

  const { data, loading, refetch } = useGetClients();

  const [showAddClientModal, setShowAddClientModal] = useState(false);

  const onCreateClientComplete = async (client) => {
    await refetch();
    onChange({ value: client.id, label: client.name });
  };

  return (
    <div>
      <Form.Label className="fw-bold text-[#54595e] text-sm md:text-base">Cliente:</Form.Label>

      <div style={{ marginBottom: 10 }}>
        <Select
          options={data}
          onChange={onChange}
          value={value}
          required
          isLoading={loading}
          styles={customStyles}
          maxMenuHeight={250}
          menuPosition={"fixed"}
          classNamePrefix="react-select"
          className="single-select"
          placeholder="Seleziona cliente..."
          filterOption={(option, rawInput) => {
            const name = option.data.name ? option.data.name.toLowerCase() : "";
            const address = option.data.address ? option.data.address.toLowerCase() : "";
            const search = rawInput.toLowerCase();

            return name.includes(search) || address.includes(search);
          }}
        />
      </div>
      
      {!props.hide && (
        <Button
          size="sm"
          variant="outline-primary"
          style={{ margin: "10px 0 20px 0" }}
          onClick={() => setShowAddClientModal(true)}
          className="bg-[#004b8f] mb-8 border-0 hover:bg-[#ffc425] hover:text-[#004b8f] text-[#fff] text-sm md:text-base"
        >
          Aggiungi cliente
        </Button>
      )}

      <CreateClientModal
        show={showAddClientModal}
        handleClose={() => setShowAddClientModal(false)}
        onCreateComplete={onCreateClientComplete}
      />
    </div>
  );
};

SelectClients.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default SelectClients;
