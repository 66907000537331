import React from "react";
import { Container } from "react-bootstrap";

import InvoiceForm from "components/InvoiceForm";
import { useAuthContext } from "context/auth-context";

const InvoicePage = (props) => {
  const user = useAuthContext();
  const childRef = React.createRef();

  const handleClick = () => {
    childRef.current.modifyChildState();
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <div className="w-full bg-[#004b8f] text-white p-4 self-center text-center text-sm md:text-4xl font-bold fontU flex-row flex space-x-2 shadow-md">
        <img
          style={{ borderRadius: 10 }}
          className="w-36 p-2 pt-10 border-1 border-gray-700 flex"
          src="https://deltat-technology.web.app/assets/img/delta_t_technology.webp"
          alt="Logo Delta T Technology"
          onClick={handleClick}
        />
        <p className="flex self-center">Rapporto Intervento Tecnico</p>
      </div>
      <Container>
        <InvoiceForm user={user} ref={childRef}/>
      </Container>
    </div>
  );
};

export default InvoicePage;
